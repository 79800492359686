import React, { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Flipped, Flipper } from 'react-flip-toolkit';
import PeriodicImg from './assets/images/periodic.inline.svg';
import RecurrentImg from './assets/images/recurrent.inline.svg';
import UseImg from './assets/images/use.inline.svg';

import CySaasLogoImg from './assets/images/cysass.inline.svg';
import ReadMoreLogoImg from './assets/images/readmore.inline.svg';
import YouFoneLogoImg from './assets/images/youphone.inline.svg';

import BgImg from './assets/images/bg.inline.svg';

import './styles.scss';

const experiences = [
  {
    content: {
      billingPeriod: {
        from: new Date(2020,0,1),
        to: new Date(2020,0,31),
      },
      highlightColor: '#233456',
      items: [
        {
          details: 'subscriptions.experience.experience_1.installation_price',
          qty: 1,
          total: '$29.99',
          unitPrice: '$29.99',
        },
        {
          details: 'subscriptions.experience.experience_1.enterprise_plan',
          qty: 21,
          total: '$1050.00',
          unitPrice: '$50.00',
        },
      ],
      logo: <CySaasLogoImg />,
      name: 'cySaaS',
      total: '$1079.99'
    },
    description: 'subscriptions.experience.experience_1.tab_long_description',
    icon: <RecurrentImg className="subscriptions__experience__selector__icon mb-md-3"/>,
    shortDescription: 'subscriptions.experience.experience_1.tab_short_description',
  },
  {
    content: {
      billingPeriod: {
        from: new Date(2020,0,1),
        to: new Date(2020,0,31),
      },
      highlightColor: '#8E6EAD',
      items: [
        {
          details: 'subscriptions.experience.experience_2.plan_gold',
          qty: 1,
          total: '$79.99',
          unitPrice: '$79.99',
        },
        {
          details: 'subscriptions.experience.experience_2.used_mb',
          qty: 200,
          total: '$18.00',
          unitPrice: '$0.09',
        }
      ],
      logo: <YouFoneLogoImg/>,
      name: 'youFone',
      total: '$97.99'
    },
    description: 'subscriptions.experience.experience_2.tab_long_description',
    icon: <UseImg className="subscriptions__experience__selector__icon mb-md-3"/>,
    shortDescription: 'subscriptions.experience.experience_2.tab_short_description',
  },
  {
    content: {
      billingPeriod: {
        from: new Date(2020,0,1),
        to: new Date(2020,2,31),
      },
      highlightColor: '#0DC298',
      items: [
        {
          details: 'subscriptions.experience.experience_3.weekend_plan',
          qty: 3,
          total: '$119.97',
          unitPrice: '$39.99',
        }
      ],
      logo: <ReadMoreLogoImg />,
      name: 'readMore',
      total: '$119.97'
    },
    description: 'subscriptions.experience.experience_3.tab_long_description',
    icon: <PeriodicImg className="subscriptions__experience__selector__icon mb-md-3"/>,
    shortDescription: 'subscriptions.experience.experience_3.tab_short_description',
  },
]

const SubscriptionsExperience: React.FC = ({ children }) => {
  const [selectedExperience, setExperience] = useState(experiences[0]);

  return (
    <section className="py-layout-5 overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 text-center text-md-left">
            <h1 className="text-primary mb-layout-5 d-none d-lg-block"><FormattedMessage id="subscriptions.experience.title"/></h1>
            <h3 className="text-primary mb-layout-5 d-block d-lg-none"><FormattedMessage id="subscriptions.experience.title"/></h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <h6 className="text-center d-block d-lg-none mb-5"><FormattedMessage id="subscriptions.experience.mobile_title"/></h6>
            <div className="subscriptions__experience__selectors">
              {
                experiences.map(experience =>
                  <div
                    className={`
                      subscriptions__experience__selector
                      ${
                        experience.description === selectedExperience.description ?
                        'subscriptions__experience__selector--selected' : ''
                      }`
                    }
                    key={experience.description}
                    onClick={() => setExperience(experience)}
                  >
                    {experience.icon}
                    <p className="mb-0 d-none d-lg-block">
                      <FormattedMessage id={experience.description}/>
                    </p>
                    <p className="mb-0 d-block d-lg-none">
                      <FormattedMessage id={experience.shortDescription}/>
                    </p>
                  </div>
                )
              }
            </div>
          </div>

          <div className="col-lg-8 offset-lg-1 position-relative">
            <BgImg className="subscriptions__experience__bg" />
            <Flipper flipKey={selectedExperience}>
              <div className="subscriptions__experience__content mt-layout-4 mt-lg-0" style={{borderTop: `4px solid ${selectedExperience.content.highlightColor}`}}>
                <div className="subscriptions__experience__content__header">
                  <Flipped flipId="subscriptions__experience__content-logo">
                    {selectedExperience.content.logo}
                  </Flipped>
                  <Flipped
                    flipId="subscriptions__experience__content-billingPeriod"
                  >
                    <p className="lead mb-0 mt-4">
                      <FormattedMessage id="subscriptions.experience.billing_period"
                        values={{
                          from: <b style={{color: selectedExperience.content.highlightColor}}><FormattedDate value={selectedExperience.content.billingPeriod.from} month="long" day="2-digit" /></b>,
                          to: <b style={{color: selectedExperience.content.highlightColor}}>{<FormattedDate value={selectedExperience.content.billingPeriod.to} month="long" day="2-digit" />}</b>
                        }}
                      />
                    </p>
                  </Flipped>
                </div>

                <Flipped flipId="subscriptions__experience__content-headerRow">
                  <div className="subscriptions__experience__content__row subscriptions__experience__content__row--header">
                    <div className="subscriptions__experience__content__column subscriptions__experience__content__column--big">
                      <FormattedMessage id="subscriptions.experience.details_header"/>
                    </div>
                    <div className="subscriptions__experience__content__column text-center">
                      <FormattedMessage id="subscriptions.experience.qty_header"/>
                    </div>
                    <div className="subscriptions__experience__content__column text-center">
                      <FormattedMessage id="subscriptions.experience.unit_price_header"/>
                    </div>
                    <div className="subscriptions__experience__content__column text-right">
                      <FormattedMessage id="subscriptions.experience.total"/>
                    </div>
                  </div>
                </Flipped>
                {
                  selectedExperience.content.items.map(item =>
                    <Flipped flipId="subscriptions__experience__content-details" key={item.details} spring="wobbly">
                      <div className="subscriptions__experience__content__row" >
                        <div className="subscriptions__experience__content__column subscriptions__experience__content__column--big">
                           <FormattedMessage id={item.details}/>
                        </div>
                        <div className="subscriptions__experience__content__column text-center">
                          {item.qty}
                        </div>
                        <div className="subscriptions__experience__content__column text-center">
                          {item.unitPrice}
                        </div>
                        <div className="subscriptions__experience__content__column text-right">
                          {item.total}
                        </div>
                      </div>
                    </Flipped>
                    )
                }

                <Flipped flipId="subscriptions__experience__content-footerRow">
                  <div className="subscriptions__experience__content__row subscriptions__experience__content__row--footer">
                    <div className="subscriptions__experience__content__column subscriptions__experience__content__column--big">
                      <FormattedMessage id="subscriptions.experience.total"/>
                    </div>
                    <div className="subscriptions__experience__content__column text-right">
                      {selectedExperience.content.total}
                    </div>
                  </div>
                </Flipped>
              </div>
            </Flipper>
          </div>
        </div>
      </div>
    </section>
  )
};

export default SubscriptionsExperience;
