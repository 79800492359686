import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';
import SalesButton from '../../../SalesButton/SalesButton';
import HeroImg from './assets/images/hero.inline.svg';
import slantImg from './assets/images/slant.svg';
import './styles.scss';

const SubscriptionsHero: React.FC = ({ children }) => (
  <section className="hero overflow-visible">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-2 order-lg-1">

          <h1 className="display-4 mt-5 text-primary hero-title">
            <FormattedMessage id="subscriptions.hero.title"/>
          </h1>

          <p className="lead mt-5">
            <FormattedMessage id="subscriptions.hero.subtitle"
              values={defaultFormatValues}
            />
          </p>
          <div className="button-responsive-row mt-2">
            <SalesButton positionTag="top">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2">
          <HeroImg className="img-fluid"/>
        </div>
      </div>
    </div>

    <div className="hero-slant-right" style={{backgroundImage: `url(${slantImg})`}} />
  </section>
);

export default SubscriptionsHero;
