import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import ArrowLink from '../../../ArrowLink/ArrowLink';

import './styles.scss';

import javascriptImg from './assets/images/javascript.svg';
import magentoImg from './assets/images/magento.svg';
import mobileImg from './assets/images/mobile.svg';
import prestashopImg from './assets/images/prestashop.svg';
import vtexImg from './assets/images/vtex.svg';
import woocommerceImg from './assets/images/woocommerce.svg';

const integrations = [
  {
    img: prestashopImg,
    name: 'Prestashop',
  },
  {
    img: magentoImg,
    name: 'Magento',
  },
  {
    img: woocommerceImg,
    name: 'Woocommerce',
  },
  // {
  //   img: vtexImg,
  //   name: 'VTEX',
  // },
  {
    img: javascriptImg,
    name: 'Javascript',
  },
  {
    img: mobileImg,
    name: 'iOS & Android',
  },
];

const SubscriptionsIntegrations: React.FC = ({ children }) => (
  <section className="py-layout-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-5 order-2 order-md-1">
          <div className="integrations-grid mt-layout-5 mt-md-0">
            {
              integrations.map((integration, index) =>
                <div
                  className={`integrations-grid__item ${index % 2 === 0 ? 'bg-light-gray-3': ''}`}
                  key={integration.name}
                >
                  <img src={integration.img}/>
                  <h6 className="mt-3 text-dark-gray-3">{integration.name}</h6>
                </div>
              )
            }
          </div>
        </div>
        <div className="col-md-5 offset-md-2 order-1 order-md-2">
          <h2 className="text-primary">
            <FormattedMessage id="subscriptions.integrations.title"/>
          </h2>
          <p className="lead" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="subscriptions.integrations.body"
              values={defaultFormatValues}
            />
          </p>

          <ArrowLink to="https://docs.kushki.com/" color="primary" external={true}><FormattedMessage id="subscriptions.integrations.link"/></ArrowLink>
        </div>
      </div>
    </div>
  </section>
);

export default SubscriptionsIntegrations;
