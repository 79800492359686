import React from 'react';
import { FormattedMessage } from 'react-intl';

import ctaImg from './assets/images/cta.svg';

import SalesButton from '../../../SalesButton/SalesButton';

import './styles.scss';

const SubscriptionsCTA: React.FC = ({ children }) => (
  <section className="subscriptions-cta py-layout-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 text-center">
          <img src={ctaImg}/>
        </div>
        <div className="col-md-6">
          <h2 className="text-light mb-3 d-none d-md-block">
            <FormattedMessage id="subscriptions.cta.title"/>
          </h2>
          <h4 className="text-light mb-3 mt-5 d-block d-md-none">
            <FormattedMessage id="subscriptions.cta.title"/>
          </h4>
          <div className="button-responsive-row mt-7">
            <SalesButton big={true} positionTag="bottom">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SubscriptionsCTA;
