import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import SubscriptionsCTA from '../../components/Features/Subscriptions/SubscriptionsCTA/SubscriptionsCTA';
import SubscriptionsExperience from '../../components/Features/Subscriptions/SubscriptionsExperience/SubscriptionsExpierence';
import SubscriptionsHero from '../../components/Features/Subscriptions/SubscriptionsHero/SubscriptionsHero';
import SubscriptionsIntegrations from '../../components/Features/Subscriptions/SubscriptionsIntegrations/SubscriptionsIntegrations';
import SubscriptionsBenefits from '../../components/Features/Subscriptions/SubscriptonsBenefits/SubscriptionsBenefits';
import { LocaleTypes } from '../../data/locales';

interface SubscriptionsPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const SubscriptionsPage: React.FC<SubscriptionsPageProps> = ({ pathContext, location }) => {


  const content = () => (
    <>
      <SEO title="Pagos recurrentes" />
      <SubscriptionsHero />

      <SubscriptionsExperience />

      <SubscriptionsBenefits />

      <SubscriptionsIntegrations />

      <SubscriptionsCTA />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default SubscriptionsPage;
