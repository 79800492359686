import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import { OutboundLink } from 'gatsby-plugin-google-gtag';

import automaticImg from './assets/images/automatic.svg';
import cycleImg from './assets/images/cycle.svg';
import internationalImg from './assets/images/international.svg';
import panelImg from './assets/images/panel.svg';

import './styles.scss';

const SubscriptionsBenefits: React.FC = ({ children }) => (
  <section className="py-layout-5 bg-light-gray-3">
    <div className="container">
      <div className="row">
        <div className="col-md-5 offset-md-1 col-lg-4 offset-lg-1">
          <img src={cycleImg} className="mt-5" />
          <h4 className="text-primary mt-5 mb-3">
            <FormattedMessage id="subscriptions.benefits.benefit_1.title"/>
          </h4>
          <p className="lead">
            <FormattedMessage id="subscriptions.benefits.benefit_1.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
        <div className="col-md-5 col-lg-4 offset-lg-2">
          <img src={internationalImg} className="mt-5" />
          <h4 className="text-primary mt-5 mb-3">
            <FormattedMessage id="subscriptions.benefits.benefit_2.title"/>
          </h4>
          <p className="lead">
            <FormattedMessage id="subscriptions.benefits.benefit_2.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
        <div className="col-md-5 offset-md-1 col-lg-4 offset-lg-1">
          <img src={automaticImg} className="mt-5" />
          <h4 className="text-primary mt-5 mb-3">
            <FormattedMessage id="subscriptions.benefits.benefit_3.title"/>
          </h4>
          <p className="lead">
            <FormattedMessage id="subscriptions.benefits.benefit_3.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
        <div className="col-md-5 col-lg-4 offset-lg-2">
          <img src={panelImg} className="mt-5" />
          <h4 className="text-primary mt-5 mb-3">
            <FormattedMessage id="subscriptions.benefits.benefit_4.title"/>
          </h4>
          <p className="lead">
            <FormattedMessage id="subscriptions.benefits.benefit_4.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default SubscriptionsBenefits;
